const CLASS_HIDDEN = 'hidden';
const CLASS_HIDDEN_DEFAULT = 'hidden-default';
const CLASS_NO_JAVASCRIPT = 'no-javascript';
const COOKIE_NAME = 'SBEKUKL';
const PROPERTY_PREVIEW_CLUSTER = 'previewCluster';

class KundenSegmentierung extends HTMLElement {

  constructor() {
    super();

    this.defaultVariant = null;
    this.componentUrl = null;
    this.clusterVariantList = [];
    this.activeClusterList = [];
  }

  connectedCallback() {
    this.setElements();
    this.removeNoJs();
    this.readActiveClustersFromCookie();
    this.showComponent();
  }

  removeNoJs() {
    this.defaultVariant.classList.remove(CLASS_NO_JAVASCRIPT);
  }

  setElements() {
    this.defaultVariant = this.querySelector('div[data-cluster="default"]');
    this.clusterVariantList = this.querySelectorAll('.cluster-variants');
  }

  readActiveClustersFromCookie() {
    const cookie = this.getCookie(COOKIE_NAME);
    if (this.dataset.hasOwnProperty(PROPERTY_PREVIEW_CLUSTER)) {
        this.activeClusterList = this.dataset[PROPERTY_PREVIEW_CLUSTER].split(',');
    } else if (cookie) {
      const jsonCookieValue = this.parseBase64(cookie);
      let activeClusterString = (jsonCookieValue && jsonCookieValue.cl) ? jsonCookieValue.cl : "";
      this.activeClusterList = activeClusterString.split(',');
    }
  }

  parseBase64(token) {
    try {
      return JSON.parse(this.b64DecodeUnicode(token));
    } catch (e) {
      return null;
    }
  }

  b64DecodeUnicode(string) {
    // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings#30106551
    return decodeURIComponent(atob(string).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  showComponent() {
    const node = this.getShownNode();

    if (node.dataset.hasOwnProperty("loadUrl")) {
      this.loadComponent(node);
    }

    node.classList.remove(CLASS_HIDDEN, CLASS_HIDDEN_DEFAULT);
  }

  getShownNode() {
    for (let i = 0; i < this.clusterVariantList.length; i++) {
      const node = this.clusterVariantList[i];
      const currentClusterName = node.dataset.cluster;

      if (this.activeClusterList.indexOf(currentClusterName) !== -1) {
        return node;
      }
    }
    return this.defaultVariant;
  }

  loadComponent(node) {
    const url = node.dataset.loadUrl;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then(body => {
        node.innerHTML = body;
      }).catch(() => {
        this.showDefaultFallback(node);
      });
  }

  showDefaultFallback(node) {
    node.classList.add(CLASS_HIDDEN);
    this.defaultVariant.classList.remove(CLASS_HIDDEN, CLASS_HIDDEN_DEFAULT);
  }
}

if (!customElements.get("kunden-segmentierung")) {
  customElements.define("kunden-segmentierung", KundenSegmentierung);
}
